import NoProfile from "src/assets/png/user-default.png";
import NoFavicon from "src/assets/png/image_favicon-default.png";
import NoLogo from "src/assets/png/image_logo-default.png";
import DomoGreyScaleJWT from "src/assets/png/domo-grayscale-jwt.png";

// export const baseUrl = process.env.REACT_APP_API_BASE_URL || window.API_BASE_URL;
export const baseUrl = "https://admin-bi.certilogo.com";

export const publicImageStorage = ".s3.us-west-2.amazonaws.com";
export const apiUrl = baseUrl + "/api";
export const filePath = baseUrl + "/uploads";
export const noProfile = NoProfile;
export const noFavicon = NoFavicon;
export const domoGrayscaleJWT = DomoGreyScaleJWT;
export const noLogo = NoLogo;
